<template>
<div>
  <div class='wrapper' :class="{'blur': this.$store.state.isBlur}">
    <Header/>
      <Catalog class='main'/>
    <Footer/>
  </div>
  <Modal v-if="this.$store.state.modalShow"/>
</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Catalog from '@/components/Catalog.vue'
import Modal from '@/components/Modal.vue'

export default {
  components: {
    Header,
    Catalog,
    Footer,
    Modal,
  },
  mounted() {
    if (this.$store.state.resData) {
      let result = this.$store.state.resData;
      result['result_code'] = this.$store.state.resData.success == '1' ? '0' : '1';
      if (result.free_param) {
        let arr = result.free_param.split('/');
        arr.forEach(e => {
          this.$store.state.products.forEach(p => {
          if (p.id == e) {
            this.$store.commit('ADD_TO_BASKET', p);
          }
          });
        })
      }
      this.$store.dispatch('act_SHOW_RESULT', result);
      console.log(this.$store.state);
    }
  },
}
</script>

<style lang="scss">
@import "@/scss_variables/variables";
* {
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 16px;
  font-family: $main-font;
}
a {
  text-decoration: none;
  color: $font-dark;
}
#app {
  position: relative;
}
.blur {
  filter: blur(3px);
}
.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
  .main {
    flex: 1;
    max-width: 1150px;
    width: 85%;
    margin: 0 auto;
  }
}
.mobile {
  display: none;
}
@media (max-width: $hide-width) {
  .no-mobile {
    display: none;
  }
}

.basket {
    text-align: left;
    overflow-y: auto;
    width: 50rem;
    margin: 0 auto;
    padding: 1rem 0;
    background: $background-light;
    border: 1px solid $btn-color;
    border-radius: 0.25rem;
    @media (max-width: 920px) {
        width: 90%;
    } 
    &-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        padding: 0 1rem;
    }
    &-products {
        margin-top: 0.5rem;
    }
    .column {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 2rem 0.25rem;
        padding-bottom: 1.5rem !important;
        label {
            margin-left: 0.5rem;
            cursor: pointer;
        }
    }
    .single {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0.5rem 1rem;
        &-img {
            height: 67px;
            flex: 1;
            img {
                max-width: 100px;
                border-radius: 5px;
                height: 67px;
                @media (max-width: 625px) {
                    max-width: 140px;
                }
            }
        }
        &-data {
            flex: 4; 
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            &-name {
                flex: 2;
            }
            &-price {
                flex: 1;
            }
            &-id {
                flex: 1;
            }
            @media (max-width: 480px) {
                flex-direction: column;
            }
        }
        @media (max-width: 480px) {
            flex-direction: column;
        }
        align-items: center;
    }
    // .single:last-child, .single:first-child {
    //     border-top: 1px solid silver;
    // }
    .single:first-child {
        padding-top: 1.5rem;
    }
    .single:last-child {
        padding-bottom: 1.5rem;
    }
    .total {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        font-size: 1rem;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        .big {
            font-size: 1.5rem;
            color: white;
            background-color: $btn-color;
        }
        .big:hover {
            color: $btn-color;
            background-color: transparent;
        }
    }
}
#wid, #mer {
    height: 20px;
    width: 20px;
}
.r-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
}
.flex-s {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    width: 100%;
}
.grb {
    background-color: #9e9e9e1f;
    border-top: 1px solid silver;
    border-bottom: 1px solid silver;
}
</style>
