<template>
    <div class="basket">
        <div class="basket-header">
            <div>
                <h2>{{this.result.title}}</h2>
                <p class="gr">{{this.result.description}} 😸</p>
                <p v-if="this.result.sign && !this.result.sign_v">sign verify: {{ this.result.sign_v }}</p>
            </div>
            <Button :listener="() => {this.closeResult()}" text="&#10006;"/>
        </div>
        <div v-if="Object.keys(this.$store.state.inBasket).length > 0" class="basket-products">
            <div class="grb">
                <div :key="item.id" v-for="item in this.$store.state.inBasket" class="single">
                <div class="single-img">
                    <img :src="require(`../assets/products/${item[0].img}`)" alt='cat'>
                </div>
                <div class='single-data'>
                    <p class="single-data-name">{{item[0].name}} </p>
                    <p class="single-data-price"><span style="font-weight:600">{{item[0].price}}</span> {{item[0].t}} </p>
                    <p class="single-data-id"> ID: {{item[0].id}}</p>
                    <div class='single-data-actions'>
                        <!-- <Button class='disabled' v-bind:text="this.result.button"/> -->
                        <p class="rb">{{ this.result.button }}</p>
                    </div>
                </div>
                </div>
            </div>
            <div style="padding: 0 1rem; padding-top: 1rem !important;" class='single-data flex-s'>
                <p class="gr" style="font-size: 1.1rem;">И не забудтье: кот сам себя не погладит!</p>
                <div>
                    <Button v-if="this.result.status" :listener="() => {this.repeat()}" text='Повторить' class='big mr-1'/>
                    <Button :listener="() => {this.closeResult()}" text='Закрыть' class='big'/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
    components: {
        Button
    },
    computed: { 
        result() {
            let res = this.$store.state.payResult;
            return {
                status: res.res['result_code'],
                button: res.res['result_code'] == '0' ? 'Оплачено' : 'Не оплачено',
                description: res.res['result_code'] == '0' ? 'Ваш кот будет ждать вас в условленном месте!' : res.res['1'],
                title: res.res['result_code'] == '0' ? 'Поздравляем!': 'Ошибка!',
                sign: res.res['sign'],
                sign_v: res.res['sign_verify'],
                };
        },
    },
    methods: {
        closeResult() {
            this.$store.commit('CLEAR_BASKET');
            this.$store.commit('PAY_RESULT', false);
            this.$store.commit('SHOW_MODAL');
        },
        repeat() {
            this.$store.commit('PAY_RESULT', false);
            this.$store.commit('SHOW_BASKET');
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/scss_variables/variables";

.gr {
    font-style: italic;
    color: $font-semidark;
}
.rb {
    color: #4CAF50;
}
.mr-1 {
    margin-right: 1rem !important;
}
</style>