<template>
    <div class='footer'>
        Copyright © Ваш сайт 2021
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "@/scss_variables/variables";
.footer {
    font-size: 1rem;
    text-align: center;
    padding: 2.5rem;
    background-color: $background-dark;
    color: $font-light;
}
</style>