<template>
    <div class="basket">
        <div class="basket-header">
            <h2>КОРЗИНА</h2>
            <Button :listener="() => {this.$store.dispatch('act_SHOW_BASKET')}" text="&#10006;"/>
        </div>
        <div v-if="Object.keys(this.$store.state.inBasket).length > 0" class="basket-products">
            <div class="grb">
                <div :key="item.id" v-for="item in this.$store.state.inBasket" class="single">
                <div class="single-img">
                    <img :src="require(`../assets/products/${item[0].img}`)" alt='cat'>
                </div>
                <div class='single-data'>
                    <p class="single-data-name">{{item[0].name}} </p>
                    <p class="single-data-price"><span style="font-weight:600">{{item[0].price}}</span> {{item[0].t}} </p>
                    <p class="single-data-id"> ID: {{item[0].id}}</p>
                    <div class='single-data-actions'>
                        <Button :listener="() => this.RemoveFromBasket(item[0].id)" text='Убрать'/>
                    </div>
                </div>
                </div>
            </div>
            <div class="single total">
                <div class="column">
                    <div class="r-cont">
                        <input id="wid" type="radio" value="widget" v-model="type">
                        <label for="wid">Открыть виджет</label>
                    </div>
                    <div class="r-cont">
                        <input id="mer" type="radio" value="merchant" v-model="type">
                        <label for="mer">Простой редирект</label>
                    </div>
                </div>
                <div class='single-data flex-s'>
                    <p>Итог: <span style="font-weight:600">{{this.total}}</span> {{ 'RUB' }}</p>
                    <Button :listener="this.send" :disabled="this.type ? false : true" text='Купить' class='big'/>
                </div>
            </div>
        </div>
        <div v-else class="basket-products">
            <div class='single'>
                <h2>Корзина пуста</h2>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button.vue'
import {openWidget} from '@/widget/widget.class.js';

export default {
    components: {
        Button
    },
    data() {
        return {
            type: false,
        }
    },
    computed: {
        total() {
            return this.$store.getters.get_TOTAL_SUMM;
        }
    },
    methods: {
        RemoveFromBasket(id) {
            this.$store.commit('REMOVE_FROM_BASKET', id);
        },
        send() {
            if (this.type == 'widget') {
                this.sendToFrameTest();
            } else if (this.type == 'merchant') {
                this.sendToMerchant();
            }
        },
        sendToMerchant() {
            let context = this.$store;
            console.log(this.$store.state)
            let pay_params = this.$store.state.sendData.merchant_params;
            pay_params['sum'] = this.total;
            pay_params['free_param'] = Object.keys(this.$store.state.inBasket).join('/');
            // console.log(pay_params)
            this.axios.post('sign.php', pay_params).then(res => {
                if (res.data.sign) {
                    pay_params['sign'] = res.data.sign;
                    // console.log(pay_params)
                    var url = new URL(pay_params.proc_url);
                        for(let p in pay_params) {
                            url.searchParams.append(p, pay_params[p]);
                        }
                        // console.log(url)
                        window.location.href = url;
                    }
            });
        },
        sendToFrameTest() {
            let context = this.$store;
            let pay_params = this.$store.state.sendData.merchant_params;
            pay_params['sum'] = this.total;
            pay_params['free_param'] = Object.keys(this.$store.state.inBasket).join('/');
            this.axios.post('sign.php', pay_params).then(res => {
                if (res.data.sign) {
                    pay_params['sign'] = res.data.sign;
                    let params = {
                        frame_id: 'test_frame',
                        frame_name: 'to_pay',
                        pay_params: pay_params,
                        onSuccess: function (result) {
                            context.commit('PAY_RESULT', {res:result});
                            console.log(context.state);
                        },
                        onFail: function (result) {
                            context.commit('PAY_RESULT', {res:result});
                            console.log(context.state);
                        }};
                        console.log (params);
                        openWidget(params);
                        this.$store.commit('SHOW_BASKET');
                    // var url = new URL(pay_params.proc_url);
                    //     for(let p in pay_params) {
                    //         url.searchParams.append(p, pay_params[p]);
                    //     }

                    //     window.location.href = url;
                    }
            });
        }
    },
}
</script>

<style lang="scss">

</style>